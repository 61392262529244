<script setup lang="ts">
</script>

<template>
    <div>
        <header>
            <HeaderNavBar/>
        </header>

        <main class="flex flex-col min-h-screen bg-gray-200">
            <slot/>
        </main>

        <footer></footer>
    </div>
</template>

<style scoped>
/*body {*/
/*background-image: url("assets/img/index/conex-bg.svg");
/*background-size: cover;*/
/*background-repeat: no-repeat;*/
/*background-position: center center;*/
/*}*/

main {
    min-height: calc(100vh - 88px);
}
</style>
