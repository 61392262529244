<script setup lang="ts"></script>

<template>
  <nav class="p-3" style="background-color: #00aeef">
    <div class="flex items-center justify-center md:justify-start">
      <NuxtLink to="/">
        <img class="h-16" src="/assets/img/logo-white-high_res.png" alt="Logo" />
      </NuxtLink>
    </div>
  </nav>
</template>

<style scoped></style>
